import React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const FiftyFifty = (props) => {

  console.log(props.data.edges)

    return(
      <div>
        {
          props.data.edges.map((item,index)=>{
            return(
              <HomeBlock key={item.node.id}>

                    <div className={index%2===0?'even':'odd'}>

                  
                      { item.node.localImage !== null && 
                      <StyledBackgroundImage
                        Tag="section"
                        fluid={item.node.localImage.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                      >
                       <div className="tiangle"></div>
                      </StyledBackgroundImage>
                    }
                    <div>
                      <h3>{ props.type === 'brands' ? item.node.brand : (props.type === 'posts') ? item.node.title  : item.node.name }</h3>
                      { props.type === 'brands' ?
                       <div dangerouslySetInnerHTML={{ __html: item.node.content }}></div>
                       : (props.type === 'posts') ?
                       <div dangerouslySetInnerHTML={{ __html: item.node.intro }}></div>
                       :
                       <div dangerouslySetInnerHTML={{ __html: item.node.intro }}></div>
                      }
                      <Link to={`/${props.type}/${
                        props.type === 'brands' ? item.node.brand.toLowerCase().replace(/\s/g , "-")
                        :
                        (props.type === 'posts') ? item.node.title.toLowerCase().replace(/\s/g , "-")
                        
                        : 
                        
                        item.node.name.toLowerCase().replace(/\s/g , "-") }`}>Read more ...</Link>
                    </div>
                  </div> 
              </HomeBlock>
              )
            })
          }
      </div>
    )
}
  const StyledBackgroundImage = styled(BackgroundImage)`
    margin-bottom : 0px;
    height: 55vh;
    background-size: cover;
    width: 50%;
    position: relative;

    @media only screen and (max-width: 980px) {
          width: 100%;
          float: none;
          height: 72vw;
      }
  `

const HomeBlock = styled.div`
    
    background: rgb(246, 247, 249); 

    h3{
      font-weight: 400;
      text-transform: uppercase;
      font-size: 32px;
      color: #000;
    }

    ::after{
      display: block;
      content: '';
      clear: both;
    }

    .even>section{
      float:left;
      .tiangle{
        width: 0; 
        height: 0; 
        right: -1px;
        top: -1px;
        position: absolute;
        border-top: 49vh solid transparent;
        border-bottom: 60px solid transparent; 
        border-right: 60px solid #F6F7F9;
      }
    }

    .even>div{
      padding:  0 50px;
      width: 50%;
      float: left;
      padding: 50px;
    }

    .odd>section{

      float:right;

      .tiangle{
        width: 0; 
        height: 0; 
        left: -1px;
        top: -1px;
        position: absolute;
        border-top: 49vh solid transparent;
        border-bottom: 60px solid transparent; 
        border-left: 60px solid #F6F7F9;

        
      }
    }

    .odd>div{
      padding:  0 50px;
      width: 50%;
      float: right;
      padding: 50px;
    }

    a>span{
      color: black;
      font-weight: 500;
      text-transform: uppercase;
    }

    @media only screen and (max-width: 980px) {


      .even>section{
        .tiangle{
          left: 0;
          bottom: -1px;
          top: inherit;
          position: absolute;
          border-right: 60px solid transparent;
          border-bottom: 60px solid #f7f7f7;
          border-left: calc(100vw - 60px) solid transparent;
          border-top: 0;
        }
      }

      .odd>section{
        .tiangle{
          left: 0;
          bottom: -1px;
          top: inherit;
          position: absolute;
          border-left: 60px solid transparent;
          border-bottom: 60px solid #f7f7f7;
          border-right: calc(100vw - 60px) solid transparent;
          border-top: 0;
        }

      }

    .odd div , .even div {
      width: 100%;
    }
  
    }

    `
    export default FiftyFifty