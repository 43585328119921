import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Brands = (props) => {

  const BrandBox = styled.div`
    background: white;
    align-self: flex-end;
    width: 30vw;
    padding: 50px;
    position: absolute;
    left: 27vw;
    top: 5vw;

    h2{
      // font-size: 1.8rem;
      font-family: Comfortaa;
      font-weight:200;
      border-bottom: 1px solid #D82227;
      padding-bottom: 1rem;
    }
   h4{
      font-family: Montserrat;
      font-weight: 300;
      // font-size: 1.2rem;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  `;

  const InfoMobile = styled.div`
    display: none;
    padding: 50px;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  `;

  const StyledBackgroundImage = styled(BackgroundImage)`
  height : 65vw;
  max-height: 88vh; 
  position: relative;
  margin-bottom: 3px; 
  `

  return(
    <span>
    <StyledBackgroundImage
          Tag="section"
          fluid={props.image}
          backgroundColor={`#040e18`}
        >
         <BrandBox >
                <h1>{props.title}</h1>
                <h4>{props.subtitle}</h4>
                <p>{props.text}</p>    
         </BrandBox>  
    </StyledBackgroundImage>
          <InfoMobile>
                <h2>{props.title}</h2>
                <h4>{props.subtitle}</h4>
                <p>{props.text}</p>    
         </InfoMobile>
    </span>

  )
  
}

export default Brands
