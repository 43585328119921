import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import BgImage from "../components/bgImage"
import FiftyFifty from '../components/fiftyfifty'

const Brands = (props) => {

  console.log(props)
  return(
    <section>
      <SEO title="Brands" description='Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings' />
      { props.data.mainImage.childImageSharp.fluid !== null &&
      <BgImage 
        image={props.data.mainImage.childImageSharp.fluid}
        text="Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings. Windows and doors crafted from our profiles are often the most prominent feature in prestigious estates and custom homes."
        title="Brands"
        subtitle="Minimalistic modern design"
      ></BgImage>
      }
      <FiftyFifty  data={props.data.allBrands} type="brands" />
    </section>
  )
}

export const query  = graphql`
{
  mainImage: file(relativePath: {regex: "/Arte/"}) {
       childImageSharp {
         fluid(maxWidth: 1980) {
           ...GatsbyImageSharpFluid
         }
       }
  }
  allBrands {
    edges {
      node {
        brand
        localImage {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        content
        id
      }
    }
  }
}
`
export default Brands